<template>
    <add-item
        :itemObject="item"
        :baseUrl="baseUrl"
        :submitBtnTitle="submitBtnTitle"
        :title="title"
    />
</template>

<script>
import AddItem from './../components/AddItem'

export default {
    components: {
        AddItem
    },
    data() {
        return {
            submitBtnTitle: this.$t('messages.navisionItemCreate'),
            title: this.$t('messages.navisionItemCreate'),
            baseUrl: '/navision-items',
            item: {
                itemCategorySelect: {
                    label: this.$helper.getEnumTranslation("ITEM_CATEGORY", 'NAVISION_ITEM', this.$i18n.locale),
                    id: 'NAVISION_ITEM'
                }
            }
        }
    },
}
</script>
